import Bugsnag = require("Everlaw/Bugsnag");
import * as Base from "Everlaw/Base";
import * as Chronology from "Everlaw/Chron/Chronology";
import { EverId, everIdSelector } from "Everlaw/EverAttribute/EverId";
import * as Perm from "Everlaw/PermissionStrings";
import { Recommendation } from "Everlaw/SmartOnboarding/Recommendation";
import * as User from "Everlaw/User";

/**
 * TODO: replace with https://developer.mozilla.org/en-US/docs/Web/API/Element/checkVisibility
 * when widely available.
 */
function isVisible(element: Element): boolean {
    const htmlElem = element as HTMLElement;
    return !!(htmlElem.offsetWidth || htmlElem.offsetHeight || htmlElem.getClientRects().length);
}

/**
 * Given a selector string, find all visible elements matching the string. If multiple elements
 * are found, we'll notify bugsnag with an error, and return undefined.
 */
function getVisibleMatchingElements(queryString: string): Element[] | undefined {
    const matchingElements = Array.from(document.querySelectorAll(queryString)).filter((elm) =>
        isVisible(elm),
    );
    if (matchingElements.length > 1) {
        Bugsnag.notify(Error(`Multiple elements matching the selector ${queryString} were found.`));
        return;
    }
    return matchingElements;
}

/**
 * Waits for the given element to exist in the Dom and returns a reference to that element.
 * If we do not observe the element within the given timeout, we'll reject the promise.
 *
 * @param element the EverId to wait on or the element itself.
 * @param recommendation the Recommendation we'll reset if the promise is rejected.
 * @param timeout the time we'll wait for the element in milliseconds.
 */
export function waitFor(
    element: EverId,
    recommendation: Recommendation,
    timeout = 5000,
): Promise<Element> {
    return new Promise((resolve, reject) => {
        const queryString = everIdSelector(element);
        const matchingElements = getVisibleMatchingElements(queryString);
        if (!matchingElements) {
            reject(`Found multiple elements for ${element} to attach recommendation to.`);
            return;
        }
        const selectedElement = matchingElements[0];
        if (selectedElement) {
            resolve(selectedElement);
            return;
        }

        // eslint-disable-next-line prefer-const
        let timeoutId: number;
        // eslint-disable-next-line prefer-const
        let observer: MutationObserver;

        const checkIfElementExists = () => {
            const visibleMatchingElements = getVisibleMatchingElements(queryString);
            if (!visibleMatchingElements) {
                reject(`Found multiple elements for ${element} to attach recommendation to.`);
                return;
            }
            if (visibleMatchingElements[0]) {
                clearTimeout(timeoutId);
                resolve(visibleMatchingElements[0]);
                observer.disconnect();
            }
        };

        observer = new MutationObserver(checkIfElementExists);

        const handleTimeout = () => {
            observer.disconnect();
            Bugsnag.notify(
                Error(
                    `Error in waiting for frontend element ${element} to attach recommendation to.`,
                ),
            );
            recommendation.setAsDeactivated();
            recommendation.resetRecommendationStatus();
            reject(`Timed out waiting for Recommendation element ${element} to exist.`);
        };

        timeoutId = window.setTimeout(handleTimeout, timeout);

        observer.observe(document.body, {
            attributes: true,
            childList: true,
            subtree: true,
        });
        checkIfElementExists();
    });
}

export function hasVisibleChrons(): boolean {
    return Base.get(Chronology).some((c) =>
        User.me.can(Perm.READ, c, User.Override.ELEVATED_OR_ORGADMIN),
    );
}
