import Argument = require("Everlaw/Argument");
import Base = require("Everlaw/Base");
import Bates = require("Everlaw/Bates");
import ChronCategory = require("Everlaw/Chron/ChronologyCategory");
import ChronLabel = require("Everlaw/Chron/ChronologyLabel");
import ChronologySession = require("Everlaw/Chron/ChronologySession");
import { ColorTokens } from "design-system";
import Metadata = require("Everlaw/Metadata");
import Project = require("Everlaw/Project");
import Rest = require("Everlaw/Rest");
import Type = require("Everlaw/Type");
import UserObject = require("Everlaw/UserObject");
import { Color } from "Everlaw/ColorUtil";
import { ElevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";

class Chronology extends UserObject implements Chronology.ChronObject {
    get className() {
        return "Chronology";
    }
    override id: Chronology.Id;
    name: string;
    categories: ChronCategory[];
    arguments: Argument.Id[];
    prefixPriorityList: string[];
    docNamingPriority: number[];
    docDatingPriority: number[];
    size: number;
    mySession: ChronologySession;
    // Whether the chron is the invisible parent of an orphan draft (not whether the user has read permissions!)
    userVisible: boolean;
    hidden: boolean;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
        this.categories = !this.categories
            ? []
            : this.categories.map((c: any) => {
                  c.chronologyId = this.id;
                  return Base.set(ChronCategory, c);
              });
        this.prefixPriorityList = this.prefixPriorityList || [];
        this.docNamingPriority = this.docNamingPriority || [];
        this.docDatingPriority = this.docDatingPriority || [];
        params.chronLabels && Base.set(ChronLabel, params.chronLabels);
    }
    override display() {
        return this.name;
    }
    getColor() {
        return Chronology.COLOR;
    }
    getChronLabels() {
        var labels: ChronLabel[] = [];
        this.categories.forEach(function (c) {
            labels.push(...Base.get(ChronLabel, c.chronLabels));
        });
        return labels;
    }
    getCategories() {
        return this.categories;
    }
    getBatesPrefixList(): Bates.Prefix[] {
        return Base.get(Bates.Prefix, this.prefixPriorityList);
    }
    /*
     * Fields should only be saved if they exist and match the expected type, to prevent
     * any unusual behavior
     */
    setDocNamingPriority(newDocNamingPriority: number[]) {
        if (!newDocNamingPriority) {
            this.docNamingPriority = [];
            return;
        }
        this.docNamingPriority = newDocNamingPriority
            .filter((fieldId) => {
                if (
                    fieldId === Chronology.DOC_BATES_SETTING_ID
                    || fieldId === Chronology.DOC_CTRL_NUM_SETTING_ID
                ) {
                    return true;
                }
                const field = Base.get(Metadata.Field, fieldId);
                return field && field.type === Type.TEXT;
            })
            .filter((field) => !!field);
    }
    /*
     * Static helper function for taking a list of metadata field IDs and producing a list of name
     * metadata fields, with filler primitives for the bates/control number IDs, so this can be used
     * outside of getDocNamingPriority.
     */
    static nameIdListToNamePriority(nameIds: number[]) {
        if (nameIds && nameIds.length > 0) {
            return nameIds
                .map((fieldId) => {
                    if (fieldId === Chronology.DOC_CTRL_NUM_SETTING_ID) {
                        return new Base.Primitive<number>(
                            Chronology.DOC_CTRL_NUM_SETTING_ID,
                            "Control Number",
                        );
                    } else if (fieldId === Chronology.DOC_BATES_SETTING_ID) {
                        return new Base.Primitive<number>(
                            Chronology.DOC_BATES_SETTING_ID,
                            "Bates Number",
                        );
                    } else {
                        return Base.get(Metadata.Field, fieldId);
                    }
                })
                .filter((field) => !!field);
        } else {
            return null;
        }
    }
    /*
     * docNamingPriority and docDatingPriority are returned as int[] from the backend, so we have to
     * store them as number[] here and transform them into Chronology.DocSettingsField[] in the getter instead of
     * just storing them as Chronology.DocSettingsField[]
     */
    getDocNamingPriority(): Chronology.DocSettingsField[] {
        return (
            Chronology.nameIdListToNamePriority(this.docNamingPriority)
            || Chronology.getDefaultNamePriority()
        );
    }
    /*
     * Fields should only be saved if they exist and match the expected type, to prevent
     * any unusual behavior
     */
    setDocDatingPriority(newDocDatingPriority: number[]) {
        this.docDatingPriority = newDocDatingPriority.filter((fieldId) => {
            if (fieldId === Chronology.DOC_NO_DATE_SETTING_ID) {
                return true;
            }
            const field = Base.get(Metadata.Field, fieldId);
            return field && field.type === Type.DATE_TIME;
        });
    }
    /*
     * docNamingPriority and docDatingPriority are returned as int[] from the backend, so we have to
     * store them as number[] here and transform them into Chronology.DocSettingsField[] in the getter instead of
     * just storing them as Chronology.DocSettingsField[]
     */
    getDocDatingPriority(): Chronology.DocSettingsField[] {
        if (this.docDatingPriority && this.docDatingPriority.length > 0) {
            return this.docDatingPriority
                .map((fieldId) => {
                    switch (fieldId) {
                        case Chronology.DOC_NO_DATE_SETTING_ID:
                            return new Base.Primitive<number>(
                                Chronology.DOC_NO_DATE_SETTING_ID,
                                "(No Date)",
                            );
                        case Chronology.FAMILY_DATE_FIELD_ID:
                            return Metadata.includeEverlawFamilyDateField(false)
                                ? Base.get(Metadata.Field, fieldId)
                                : null;
                        default:
                            return Base.get(Metadata.Field, fieldId);
                    }
                })
                .filter((field) => !!field);
        } else {
            return Chronology.getDefaultDatePriority();
        }
    }

    rename(newname: string): Promise<string> {
        ga_event("Chronology", "Rename");
        return Rest.post("chronology/rename.rest", {
            id: this.id,
            name: newname,
        }).then((data) => {
            this._mixin(data);
            Base.publish(this);
            return this.name;
        });
    }
    getChronId() {
        return this.id;
    }
    setHidden(val: boolean) {
        return Rest.post("chronology/setHidden.rest", {
            id: this.id,
            val,
        })
            .then(() => {
                this.hidden = val;
            })
            .finally(() => {
                Base.publish(this);
            });
    }
    @ElevatedRoleConfirm("creating a new Story")
    static create(name: string, onSuccess?: (c: Chronology) => void, onError?: () => void) {
        Rest.post(Project.CURRENT.url("chronology/newChronology.rest"), { name }).then(
            (chronology) => {
                chronology = Base.set(Chronology, chronology);
                onSuccess && onSuccess(chronology);
            },
            (e) => {
                onError && onError();
                throw e;
            },
        );
    }
}

module Chronology {
    export type Id = number & Base.Id<Chronology>;

    export type DocSettingsField = Metadata.Field | Base.Primitive<number>;
    // These constants should match those in Chronology.java
    export const DOC_BATES_SETTING_ID = 0;
    export const DOC_NO_DATE_SETTING_ID = 0;
    export const DOC_CTRL_NUM_SETTING_ID = -1;
    export const FAMILY_DATE_FIELD_ID = Metadata.VirtualFieldId.FAMILY_DATE;
    // Constants which rest requests use to denote specific, recoverable errors. If changed, the analogous constants in
    // ChronologyController.java must be changed too.
    export const ALREADY_IN_STORY_ERR = "ALREADY_IN_STORY";
    export const PRODFAM_IN_STORY_ERR = "PRODUCTION_FAMILY_MEMBER_IN_STORY";
    export const NON_UNIQUE_HIGHLIGHT_NAME_ERR = "NON_UNIQUE_HIGHLIGHT_NAME";
    export const UNDO_FAILED = "UNDERLYING_OBJECT_DELETED";

    export const PRODFAM_DIALOG_HEADER = "Document cannot be added to Story";
    export const PRODFAM_DIALOG_TEXT =
        "This document cannot be added to the Story, "
        + "because another produced version of this document is already in the Story. To resolve this issue, "
        + "update your Story's prefix priority list, or remove the other document from your Story.";

    export const COLOR = Color.fromEverColor(ColorTokens.OBJECT_STORY);

    export interface ChronObject extends Base.SecuredObject {
        getChronId(): Chronology.Id;
    }

    // The following two methods should match the default priorities specified in
    // ChronologyService.java's namePriorityListOrDefault and datePriorityListOrDefault, respectively
    export function getDefaultNamePriority(): Chronology.DocSettingsField[] {
        return [
            Metadata.fieldByName(Metadata.Canonical.TITLE),
            Metadata.fieldByName(Metadata.Canonical.SUBJECT),
            Metadata.fieldByName(Metadata.Canonical.FILENAME),
            new Base.Primitive<number>(Chronology.DOC_BATES_SETTING_ID, "Bates Number"),
            new Base.Primitive<number>(Chronology.DOC_CTRL_NUM_SETTING_ID, "Control Number"),
        ].filter(
            (field) => field !== null && (!(field instanceof Metadata.Field) || field.visible),
        );
    }

    export function getDefaultDatePriority(): Chronology.DocSettingsField[] {
        return [
            Metadata.fieldByName(Metadata.DocTypeAlias.PRIMARY_DATE),
            Metadata.includeEverlawFamilyDateField(false) ? Metadata.getFamilyDateField() : null,
            Metadata.fieldByName(Metadata.Canonical.DATE_SENT),
            Metadata.fieldByName(Metadata.Canonical.DATE_RECEIVED),
            Metadata.fieldByName(Metadata.Canonical.DATE_MODIFIED),
            Metadata.fieldByName(Metadata.Canonical.DATE),
            Metadata.fieldByName(Metadata.Canonical.DATE_CREATED),
            new Base.Primitive<number>(Chronology.DOC_NO_DATE_SETTING_ID, "(No Date)"),
        ].filter(
            (field) => field !== null && (!(field instanceof Metadata.Field) || field.visible),
        );
    }
}

export = Chronology;
