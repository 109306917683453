import Base = require("Everlaw/Base");
import Category = require("Everlaw/Category"); // Circular dependency, types only
import CodeOrder = require("Everlaw/CodeOrder");
import { colorForId } from "Everlaw/ColorUtil";
import { ElevatedRoleConfirm } from "Everlaw/ElevatedRoleConfirm";
import Str = require("Everlaw/Core/Str");
import Perm = require("Everlaw/PermissionStrings");
import Rest = require("Everlaw/Rest");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import User = require("Everlaw/User");
import Util = require("Everlaw/Util");

class Code extends Base.SecuredObject implements Base.Colored {
    get className() {
        return "Code";
    }
    override id: Code.Id;
    name: string;
    categoryId: Category.Id;
    aiCodingEnabled: boolean;
    criterion: string;
    constructor(params: any) {
        super(params);
        this._mixin(params);
    }
    override _mixin(params: any) {
        Object.assign(this, params);
    }
    rename(newName: string) {
        return Rest.post("codes/rename.rest", {
            id: this.id,
            name: newName,
        }).then((data: { codeOrder: CodeOrder; code: Code }) => {
            Base.set(CodeOrder, data.codeOrder);
            return Base.set(Code, data.code);
        });
    }
    remove() {
        const performDelete = () => {
            return Rest.post("codes/delete.rest", { id: this.id }).then(() => {
                this._remove();
                return this;
            });
        };
        return new Promise<Code>((resolve, reject) => {
            import("Everlaw/Property").then((Property) => {
                Rest.post("search/searchInfo.rest", { eql: new Property.Code(this).toString() })
                    .then((sr) => {
                        const count = parseInt(sr.numResults);
                        if (count > 0) {
                            QueryDialog.create({
                                title: "Delete code confirmation",
                                prompt:
                                    "Are you sure you want to delete this code? It will be "
                                    + `removed from ${Util.countOf(count, "document")}.`,
                                submitIsSafe: false,
                                submitText: "Delete",
                                onSubmit: () => {
                                    resolve(performDelete());
                                    return true;
                                },
                                onCancel: () => {
                                    reject();
                                    return true;
                                },
                            });
                        } else {
                            resolve(performDelete());
                        }
                    })
                    .catch(reject);
            });
        });
    }
    _remove(reorderCategory: boolean = true) {
        if (reorderCategory && this.getCategory()) {
            this.getCategory().codes = this.getCategory().codes.filter((c) => !c.equals(this));
        }
        Base.remove(this);
    }
    getColor() {
        return colorForId(this.categoryId, 50);
    }
    override display() {
        return this.getCategory().display() + ": " + this.name;
    }
    getName() {
        return this.name;
    }
    getCategory(): Category {
        return Base.get("Category", this.categoryId);
    }
    override compare(other: Code | Category) {
        let order;
        if (other instanceof Code) {
            if (this.categoryId === other.categoryId) {
                // another code in the same cat
                if ((order = Base.get(CodeOrder, this.categoryId))) {
                    return order.sequence.indexOf(this.id) - order.sequence.indexOf(other.id);
                }
            } else if ((order = Base.get(CodeOrder, CodeOrder.CATEGORY_ORDER_ID))) {
                // another code in another cat
                return (
                    order.sequence.indexOf(this.categoryId)
                    - order.sequence.indexOf(other.categoryId)
                );
            }
        } else if (this.categoryId === other.id) {
            // this code's cat
            return 1; // put this code after its own category
        } else if ((order = Base.get(CodeOrder, CodeOrder.CATEGORY_ORDER_ID))) {
            return order.sequence.indexOf(this.categoryId) - order.sequence.indexOf(other.id);
        }
        const fallback = this.display().localeCompare(other.display());
        return fallback;
    }
    @ElevatedRoleConfirm("creating a code")
    static create(name: string, categoryId: number, attemptRename = true) {
        return Rest.post("codes/create.rest", {
            name,
            categoryId,
            attemptRename,
        }).then((data: { codeOrder: CodeOrder; code: Code }) => {
            Base.set(CodeOrder, data.codeOrder);
            const code = Base.set(Code, data.code);
            const cat = code.getCategory();

            // NotificationUtil.subscribeToCodesAndCategories also adds the code to it's cat
            // We don't want to add twice
            let exists = false;
            for (const other of cat.codes) {
                if (code.id === other.id) {
                    exists = true;
                    break;
                }
            }
            if (!exists) {
                cat.codes.push(code);
            }

            import("Everlaw/Category").then((Category) => {
                Base.set(Category as unknown as Base.Class<Category>, cat);
            });
            return code;
        });
    }
}

module Code {
    export type Id = number & Base.Id<"Code">;
    // If this changes, it must also be changed in Label.java (and the db).
    export const MAX_NAME_LENGTH = 255;

    export function byName(categoryName: string, codeName: string) {
        categoryName = Str.truncatedTrim(categoryName, MAX_NAME_LENGTH);
        codeName = Str.truncatedTrim(codeName, MAX_NAME_LENGTH);
        return Base.get(Code).filter((c: Code) => {
            return c.getCategory().name == categoryName && c.name == codeName;
        })[0];
    }

    /**
     * Can the current user read at least one code?
     */
    export function canReadSome(override = User.Override.NONE) {
        return User.me.hasOverride(override) || Base.get(Code).length > 0;
    }

    /**
     * Can the current user write at least one code?
     */
    export function canWriteSome(override = User.Override.NONE) {
        return Base.get(Code).some((code) => User.me.can(Perm.WRITE, code, override));
    }

    export function insertIndex(code: Code): number | undefined {
        return Base.get(CodeOrder, code.categoryId)
            ?.sequence?.filter((codeId) =>
                User.me.can(Perm.READ, Base.get(Code, codeId), User.Override.ELEVATED_OR_ORGADMIN),
            )
            .indexOf(code.id);
    }
}

export = Code;
